<template>
    <div class="kanban-page" v-if="loaded">
        <template v-if="project.pipeline_id">
            <div class="row-space-tbf">
                <div class="space-left"></div>
                <div class="content">
                    <div class="title-project">{{ project.name }}</div>
                </div>
                <div class="space-right"></div>
            </div>
    
            <div class="kanban-container">
                <div class="column-stage" v-for="kanbanItem in kanbanGroup">
                    <div class="stage-title" :style="`border-top-color: ${kanbanItem.stage.color_code};`">
                        <div class="stage-color" :style="`background-color: ${kanbanItem.stage.color_code}`"></div>
                        {{ kanbanItem.stage.name }}
                    </div>
    
                    <draggable :list="kanbanItem.tasks" :animation="200" ghost-class="ghost-card" group="tasks" class="tasks-draggable" @change="updateTaskStage($event, kanbanItem.stage.id)">
                        <!-- Each element from here will be draggable and animated. Note :key is very important here to be unique both for draggable and animations to be smooth & consistent. -->
                        <task-card
                            v-for="(task) in kanbanItem.tasks"
                            :key="task.id"
                            :data="task"
                        ></task-card>
                        <!-- </transition-group> -->
                    </draggable>
                </div>
            </div>
        </template>
        <div class="row-space-tbf" v-else>
            <div class="space-left"></div>
            <div class="content">
                <div class="alert-tbf blue with-action">
                    <div class="data">
                        <div class="title">{{ $t('projects.alert_not_pipeline_title') }}</div>
                        <div class="description">{{ $t('projects.alert_not_pipeline_desc') }}</div>
                    </div>
                    <div class="actions">
                        <button @click="showModal('advanced_settings_project', project)" class="btn-tbf blue center">
                            <div class="text">{{ $t('projects.choose_pipeline') }}</div>
                        </button>
                    </div>
                </div>
            </div>
            <div class="space-right"></div>
        </div>
    </div>
</template>

<script>
import TaskCard from '../Components/TaskKanban';
import draggable from 'vuedraggable';

export default {
    components: {
        TaskCard,
        draggable
    },
    data() {
        return {
            loaded: false,
            project: {},
            kanbanGroup: []
        };
    },
    watch: {

    },
    computed: {
        
    },
    beforeDestroy () {
        this.$root.$off("refreshProjectPage");
    },
    mounted() {
        this.getProjectData();

        this.$root.$on('refreshProjectPage', () => { this.getProjectData() } );
    }, 
    methods: {
        getProjectData() {
            axios.get(`/projects/${this.$route.params.slug}/show`, {params: { view_type: 'kanban' }})
            .then(({data}) => {
                this.kanbanGroup = data.data.body;
                // this.responsible = this.users.find(el => el.id == data.data.responsible.id);
                // this.selectedUsers = data.data.users;
                this.project = { slug: data.data.slug, id: data.data.id, name: data.data.name, updated_at: data.data.updated_at, pipeline_id: data.data.pipeline_id };
                // this.progressProject = data.data.progress;

                var title = this.$t('projects.singular');
                this.$root.$emit("navbar_title", title);
            }).catch(error => {
                if(error.response){
                    if(error.response.status == 403) {
                        this.$router.push({name: 'forbbiden'})
                    }else if(error.response.status == 404) {
                        this.$router.push({name: 'not-found'})
                    } else if(error.response.status == 500) {
                        alert(this.$t('error.500'))
                    }
                }
            })
            .finally(() => {
                setTimeout(() => {
                    this.loaded = true
                }, 0)
            })
        },
        updateTaskStage(log, stageId) {
            console.log(log)
            if(log.hasOwnProperty('added')) {
                axios.post(`/tasks/${log.added.element.id}/move-task`, { stage_id: stageId})
            }
        },
        showModal(type, data = false, itemId = false){
            this.$root.$emit('open_modal', type, data, itemId);
        }
    },
};
</script>